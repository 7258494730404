import React from 'react'
import { Link} from 'react-router-dom'
import { FaAngleLeft } from "react-icons/fa"
import { useTranslation } from "react-i18next";

//FaAngleLeft

import './accordionComp.css'
import { motion } from 'framer-motion';
// import { scaleVariant, shakeVariant } from '../yararli/utils';
import YonlendirenBilesen from './YonlendirenBilesen';

const AccordionComp = () => {
    const { t } = useTranslation(); 
    // const AnimatedFaAngleLeft = motion(FaAngleLeft)

    return (
        <section className='centerCls'>
        <YonlendirenBilesen/>
            <p>{t('accordionStrings:signUpButtonS')}</p>
            <div className='accordionContainerCls'>
                <div className="accordionItemCls">
                <input id="accordionItem-tetik-1" className="accordionItem-tetik-girisi" type="checkbox"></input>
                <label className="accordion-tetiki" htmlFor="accordionItem-tetik-1">
                {t('accordionStrings:iAmAClientTitleS')} 
                <FaAngleLeft/>
                {/* <AnimatedFaAngleLeft
                className='showInlineBlockCls'
                variants={shakeVariant}
                    initial="initial"
                    animate="shake" /> */}
                </label>
                    <p>{t('accordionStrings:thisB2BB2CGoodForYou')}</p>
                    <div className="accordion-animasyon-sarici">
                        <div className='accordion-animasyonu'>
                            <div className='accordion-donusum-sarici paddingLeft1RemCls'>
                                <div className='accordion-icerigi'>
                                    <div className="accordionItemCls">
                                    <input id="accordionItem-tetik-2"
                                    className="accordionItem-tetik-girisi"
                                    type="checkbox"></input>
                                    <label 
                                    className="accordion-tetiki-alt"
                                    htmlFor="accordionItem-tetik-2">
                                    {t('accordionStrings:iAmAClientBodyS')}
                                    <FaAngleLeft/>
                                    {/* <AnimatedFaAngleLeft
                                    className='showInlineBlockCls'
                                    variants={shakeVariant}
                                        initial="initial"
                                        animate="shake" /> */}
                                    </label>
                                    <p>{t('accordionStrings:iAmAClientPleaseChooseTheServicesYouRequest')}</p>
                                        <div className="accordion-animasyon-sarici">
                                            <div className='accordion-animasyonu'>
                                                <div className='accordion-donusum-sarici'>
                                                    <div className='accordion-icerigi'>
                                                        <div className="accordionItemCls">
                                                        <input id="accordionItem-tetik-3" className="accordionItem-tetik-girisi" type="checkbox"></input>
                                                        <label className="accordion-tetiki" htmlFor="accordionItem-tetik-3">
                                                        {t('accordionStrings:writtenNonCertifiedTranslationTitleS')}
                                                        <FaAngleLeft/>
                                                        {/* <AnimatedFaAngleLeft
                                                        className='showInlineBlockCls'
                                                        variants={shakeVariant}
                                                            initial="initial"
                                                            animate="shake" /> */}
                                                        </label>
                                                            <div className="accordion-animasyon-sarici">
                                                                <div className='accordion-animasyonu'>
                                                                    <div className='accordion-donusum-sarici'>
                                                                        <div className='accordion-icerigi-alt'>
                                                                        <p>{t('accordionStrings:writtenNonCertifiedTranslationBodyS')}</p>
                                                                            <Link to={t('accordionStrings:writtenNonCertifiedTranslationURL')}>
                                                                            <button className='redBackgroundCls' type='button'>{t('accordionStrings:signUpButtonS')} </button>
                                                                            </Link>
                                                                            <span> {t('accordionStrings:yadaS')} </span>
                                                                            <Link to={t('accordionStrings:signInURL')}>
                                                                            <button className='redBackgroundCls' type='button'>{t('accordionStrings:signInButton')} </button>
                                                                            </Link>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="accordionItemCls">
                                                        <input id="accordionItem-tetik-4" className="accordionItem-tetik-girisi" type="checkbox"></input>
                                                        <label className="accordion-tetiki" htmlFor="accordionItem-tetik-4">
                                                        {t('accordionStrings:writtenOfficeCertifiedTranslationTitleS')} 
                                                        <FaAngleLeft/>
                                                        {/* <AnimatedFaAngleLeft
                                                        className='showInlineBlockCls'
                                                        variants={shakeVariant}
                                                            initial="initial"
                                                            animate="shake" /> */}
                                                        </label>
                                                            <div className="accordion-animasyon-sarici">
                                                                <div className='accordion-animasyonu'>
                                                                    <div className='accordion-donusum-sarici'>
                                                                        <div className='accordion-icerigi-alt'>
                                                                            <p>{t('accordionStrings:writtenOfficeCertifiedTranslationBodyS')}</p>
                                                                            <Link to={t('accordionStrings:writtenOfficeCertifiedTranslationURL')}>
                                                                            <button className='redBackgroundCls' type='button'>{t('accordionStrings:signUpButtonS')} </button>
                                                                            </Link>
                                                                            <span> {t('accordionStrings:yadaS')} </span>
                                                                            <Link to={t('accordionStrings:signInURL')}>
                                                                            <button className='redBackgroundCls' type='button'>{t('accordionStrings:signInButton')} </button>
                                                                            </Link>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="accordionItemCls">
                                                        <input id="accordionItem-tetik-6" className="accordionItem-tetik-girisi" type="checkbox"></input>
                                                        <label className="accordion-tetiki" htmlFor="accordionItem-tetik-6">
                                                        {t('accordionStrings:writtenNotaryCertifiedTranslationTitleS')} 
                                                        <FaAngleLeft/>
                                                        {/* <AnimatedFaAngleLeft
                                                        className='showInlineBlockCls'
                                                        variants={shakeVariant}
                                                            initial="initial"
                                                            animate="shake" /> */}
                                                        </label>
                                                            <div className="accordion-animasyon-sarici">
                                                                <div className='accordion-animasyonu'>
                                                                    <div className='accordion-donusum-sarici'>
                                                                        <div className='accordion-icerigi-alt'>
                                                                        <p>{t('accordionStrings:writtenNotaryCertifiedTranslationBodyS')}</p>
                                                                            <Link to={t('accordionStrings:writtenNotaryCertifiedTranslationURL')}>
                                                                            <button className='redBackgroundCls' type='button'>{t('accordionStrings:signUpButtonS')} </button>
                                                                            </Link>
                                                                            <span> {t('accordionStrings:yadaS')} </span>
                                                                            <Link to={t('accordionStrings:signInURL')}>
                                                                            <button className='redBackgroundCls' type='button'>{t('accordionStrings:signInButton')} </button>
                                                                            </Link>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="accordionItemCls">
                                                        <input id="accordionItem-tetik-7" className="accordionItem-tetik-girisi" type="checkbox"></input>
                                                        <label className="accordion-tetiki" htmlFor="accordionItem-tetik-7">
                                                        {t('accordionStrings:nonCertifiedInterpretingTitleS')} 
                                                        <FaAngleLeft/>
                                                        {/* <AnimatedFaAngleLeft
                                                        className='showInlineBlockCls'
                                                        variants={shakeVariant}
                                                            initial="initial"
                                                            animate="shake" /> */}
                                                        </label>
                                                            <div className="accordion-animasyon-sarici">
                                                                <div className='accordion-animasyonu'>
                                                                    <div className='accordion-donusum-sarici'>
                                                                        <div className='accordion-icerigi-alt'>
                                                                        <p>{t('accordionStrings:nonCertifiedInterpretingBodyS')}</p>
                                                                            <Link to={t('accordionStrings:nonCertifiedInterpretingURL')}>
                                                                            <button className='redBackgroundCls' type='button'>{t('accordionStrings:signUpButtonS')} </button>
                                                                            </Link>
                                                                            <span> {t('accordionStrings:yadaS')} </span>
                                                                            <Link to={t('accordionStrings:signInURL')}>
                                                                            <button className='redBackgroundCls' type='button'>{t('accordionStrings:signInButton')} </button>
                                                                            </Link>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="accordionItemCls">
                                                        <input id="accordionItem-tetik-8" className="accordionItem-tetik-girisi" type="checkbox"></input>
                                                        <label className="accordion-tetiki" htmlFor="accordionItem-tetik-8">
                                                        {t('accordionStrings:nonCertifiedSimultaneousInterpretingTitleS')} 
                                                        <FaAngleLeft/>
                                                        {/* <AnimatedFaAngleLeft
                                                        className='showInlineBlockCls'
                                                        variants={shakeVariant}
                                                            initial="initial"
                                                            animate="shake" /> */}
                                                        </label>
                                                            <div className="accordion-animasyon-sarici">
                                                                <div className='accordion-animasyonu'>
                                                                    <div className='accordion-donusum-sarici'>
                                                                        <div className='accordion-icerigi-alt'>
                                                                        <p>{t('accordionStrings:nonCertifiedSimultaneousInterpretingBodyS')}</p>
                                                                            <Link to={t('accordionStrings:nonCertifiedSimultaneousInterpretingURL')}>
                                                                            <button className='redBackgroundCls' type='button'>{t('accordionStrings:signUpButtonS')} </button>
                                                                            </Link>
                                                                            <span> {t('accordionStrings:yadaS')} </span>
                                                                            <Link to={t('accordionStrings:signInURL')}>
                                                                            <button className='redBackgroundCls' type='button'>{t('accordionStrings:signInButton')} </button>
                                                                            </Link>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="accordionItemCls">
                                                        <input id="accordionItem-tetik-9" className="accordionItem-tetik-girisi" type="checkbox"></input>
                                                        <label className="accordion-tetiki" htmlFor="accordionItem-tetik-9">
                                                        {t('accordionStrings:notaryCertifiedInterpretingTitleS')} 
                                                        <FaAngleLeft/>
                                                        {/* <AnimatedFaAngleLeft
                                                        className='showInlineBlockCls'
                                                        variants={shakeVariant}
                                                            initial="initial"
                                                            animate="shake" /> */}
                                                        </label>
                                                            <div className="accordion-animasyon-sarici">
                                                                <div className='accordion-animasyonu'>
                                                                    <div className='accordion-donusum-sarici'>
                                                                        <div className='accordion-icerigi-alt'>
                                                                        <p>{t('accordionStrings:notaryCertifiedInterpretingBodyS')}</p>
                                                                            <Link to={t('accordionStrings:notaryCertifiedInterpretingURL')}>
                                                                            <button className='redBackgroundCls' type='button'>{t('accordionStrings:signUpButtonS')} </button>
                                                                            </Link>
                                                                            <span> {t('accordionStrings:yadaS')} </span>
                                                                            <Link to={t('accordionStrings:signInURL')}>
                                                                            <button className='redBackgroundCls' type='button'>{t('accordionStrings:signInButton')} </button>
                                                                            </Link>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div className="accordionItemCls">
                    <input id="accordionItem-tetik-10" className="accordionItem-tetik-girisi" type="checkbox"></input>
                    <label className="accordion-tetiki" htmlFor="accordionItem-tetik-10">
                    {t('accordionStrings:iAmATranslatorInterpreterTitleS')} 
                    <FaAngleLeft/>
                    {/* <AnimatedFaAngleLeft
                    className='showInlineBlockCls'
                    variants={shakeVariant}
                        initial="initial"
                        animate="shake" /> */}
                    </label>
                    <p>{t('accordionStrings:thisB2BB2CGoodForYou')}</p>
                        <div className="accordion-animasyon-sarici paddingLeft1RemCls">
                            <div className='accordion-animasyonu'>
                                <div className='accordion-donusum-sarici'>
                                    <div className='accordion-icerigi'>
                                        <div className="accordionItemCls">
                                        <input id="accordionItem-tetik-11" className="accordionItem-tetik-girisi" type="checkbox"></input>
                                        <label className="accordion-tetiki-alt" htmlFor="accordionItem-tetik-11">
                                        {t('accordionStrings:iAmATranslatorInterpreterBodyS')} 
                                        <FaAngleLeft/>
                                        {/* <AnimatedFaAngleLeft
                                        className='showInlineBlockCls'
                                        variants={shakeVariant}
                                            initial="initial"
                                            animate="shake" /> */}
                                        </label>
                                            <div className="accordion-animasyon-sarici">
                                                <div className='accordion-animasyonu'>
                                                    <div className='accordion-donusum-sarici'>
                                                        <div className='accordion-icerigi'>
                                                        <p>{t('accordionStrings:pleaseSelectTypeOfTranslatorBelow')}</p>
                                                            <div className="accordionItemCls">
                                                            <input id="accordionItem-tetik-12" className="accordionItem-tetik-girisi" type="checkbox"></input>
                                                            <label className="accordion-tetiki" htmlFor="accordionItem-tetik-12">
                                                            {t('accordionStrings:iAmATranslatorTitleS')} 
                                                            <FaAngleLeft/>
                                                            {/* <AnimatedFaAngleLeft
                                                            className='showInlineBlockCls'
                                                            variants={shakeVariant}
                                                                initial="initial"
                                                                animate="shake" /> */}
                                                            </label>
                                                                <div className="accordion-animasyon-sarici">
                                                                    <div className='accordion-animasyonu'>
                                                                        <div className='accordion-donusum-sarici'>
                                                                            <div className='accordion-icerigi-alt'>
                                                                            <p>{t('accordionStrings:iAmATranslatorBodyS')}</p>
                                                                                <Link to={t('accordionStrings:iAmATranslatorURL')}>
                                                                                <button className='redBackgroundCls' type='button'>{t('accordionStrings:signUpButtonS')} </button>
                                                                                </Link>
                                                                                <span> {t('accordionStrings:yadaS')} </span>
                                                                                <Link to={t('accordionStrings:signInURL')}>
                                                                                <button className='redBackgroundCls' type='button'>{t('accordionStrings:signInButton')} </button>
                                                                                </Link>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="accordionItemCls">
                                                            <input id="accordionItem-tetik-13" className="accordionItem-tetik-girisi" type="checkbox"></input>
                                                            <label className="accordion-tetiki" htmlFor="accordionItem-tetik-13">
                                                            {t('accordionStrings:iAmAnInterpreterTitleS')} 
                                                            <FaAngleLeft/>
                                                            {/* <AnimatedFaAngleLeft
                                                            className='showInlineBlockCls'
                                                            variants={shakeVariant}
                                                                initial="initial"
                                                                animate="shake" /> */}
                                                            </label>
                                                                <div className="accordion-animasyon-sarici">
                                                                    <div className='accordion-animasyonu'>
                                                                        <div className='accordion-donusum-sarici'>
                                                                            <div className='accordion-icerigi-alt'>
                                                                            <p>{t('accordionStrings:iAmAnInterpreterBodyS')}</p>
                                                                                <Link to={t('accordionStrings:iAmAnInterpreterURL')}>
                                                                                <button className='redBackgroundCls' type='button'>{t('accordionStrings:signUpButtonS')} </button>
                                                                                </Link>
                                                                                <span> {t('accordionStrings:yadaS')} </span>
                                                                                <Link to={t('accordionStrings:signInURL')}>
                                                                                <button className='redBackgroundCls' type='button'>{t('accordionStrings:signInButton')} </button>
                                                                                </Link>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="accordionItemCls">
                                                            <input id="accordionItem-tetik-14" className="accordionItem-tetik-girisi" type="checkbox"></input>
                                                            <label className="accordion-tetiki" htmlFor="accordionItem-tetik-14">
                                                            {t('accordionStrings:iAmATranslationStudentTitleS')} 
                                                            <FaAngleLeft/>
                                                            {/* <AnimatedFaAngleLeft
                                                            className='showInlineBlockCls'
                                                            variants={shakeVariant}
                                                                initial="initial"
                                                                animate="shake" /> */}
                                                            </label>
                                                                <div className="accordion-animasyon-sarici">
                                                                    <div className='accordion-animasyonu'>
                                                                        <div className='accordion-donusum-sarici'>
                                                                            <div className='accordion-icerigi-alt'>
                                                                            <p>{t('accordionStrings:iAmATranslationStudentBodyS')}</p>
                                                                                <Link to={t('accordionStrings:iAmATranslationStudentURL')}>
                                                                                <button className='redBackgroundCls' type='button'>{t('accordionStrings:signUpButtonS')} </button>
                                                                                </Link>
                                                                                <span> {t('accordionStrings:yadaS')} </span>
                                                                                <Link to={t('accordionStrings:signInURL')}>
                                                                                <button className='redBackgroundCls' type='button'>{t('accordionStrings:signInButton')} </button>
                                                                                </Link>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="accordionItemCls">
                    <input id="accordionItem-tetik-15" 
                    className="accordionItem-tetik-girisi" 
                    type="checkbox"></input>
                    <label className="accordion-tetiki" htmlFor="accordionItem-tetik-15">
                    {t('accordionStrings:iAmOtherTranslationProfessionalTitleS')} 
                    <FaAngleLeft/>
                    {/* <AnimatedFaAngleLeft
                    className='showInlineBlockCls'
                    variants={shakeVariant}
                        initial="initial"
                        animate="shake" /> */}
                    </label>
                    <p>{t('accordionStrings:pleaseSelectYourFunctionBelow')}</p>
                        <div className="accordion-animasyon-sarici">
                            <div className='accordion-animasyonu'>
                                <div className='accordion-donusum-sarici paddingLeft1RemCls'>
                                    <div className='accordion-icerigi'>
                                        <div className="accordionItemCls">
                                        <input id="accordionItem-tetik-16" className="accordionItem-tetik-girisi" type="checkbox"></input>
                                        <label className="accordion-tetiki-alt" htmlFor="accordionItem-tetik-16">
                                        {t('accordionStrings:iAmOtherTranslationProfessionalBodyS')} 
                                        <FaAngleLeft/>
                                        {/* <AnimatedFaAngleLeft
                                        className='showInlineBlockCls'
                                        variants={shakeVariant}
                                            initial="initial"
                                            animate="shake" /> */}
                                        </label>
                                            <div className="accordion-animasyon-sarici">
                                                <div className='accordion-animasyonu'>
                                                    <div className='accordion-donusum-sarici'>
                                                        <div className='accordion-icerigi'>
                                                            <div className="accordionItemCls">
                                                            <input id="accordionItem-tetik-17" className="accordionItem-tetik-girisi" type="checkbox"></input>
                                                            <label className="accordion-tetiki" htmlFor="accordionItem-tetik-17">
                                                            {t('accordionStrings:iAmATranslationCoordinatorProjectManagerTitleS')} 
                                                            <FaAngleLeft/>
                                                            {/* <AnimatedFaAngleLeft
                                                            className='showInlineBlockCls'
                                                            variants={shakeVariant}
                                                                initial="initial"
                                                                animate="shake" /> */}
                                                            </label>
                                                                <div className="accordion-animasyon-sarici">
                                                                    <div className='accordion-animasyonu'>
                                                                        <div className='accordion-donusum-sarici'>
                                                                            <div className='accordion-icerigi-alt'>
                                                                            <p>{t('accordionStrings:iAmATranslationCoordinatorProjectManagerBodyS')}</p>
                                                                                <Link to={t('accordionStrings:iAmATranslationCoordinatorProjectManagerURL')}>
                                                                                <button className='redBackgroundCls' type='button'>{t('accordionStrings:signUpButtonS')} </button>
                                                                                </Link>
                                                                                <span> {t('accordionStrings:yadaS')} </span>
                                                                                <Link to={t('accordionStrings:signInURL')}>
                                                                                <button className='redBackgroundCls' type='button'>{t('accordionStrings:signInButton')} </button>
                                                                                </Link>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="accordionItemCls">
                                                            <input id="accordionItem-tetik-18" className="accordionItem-tetik-girisi" type="checkbox"></input>
                                                            <label className="accordion-tetiki" htmlFor="accordionItem-tetik-18">
                                                            {t('accordionStrings:iAmATranslationEditorProofreaderTitleS')} 
                                                            <FaAngleLeft/>
                                                            {/* <AnimatedFaAngleLeft
                                                            className='showInlineBlockCls'
                                                            variants={shakeVariant}
                                                                initial="initial"
                                                                animate="shake" /> */}
                                                            </label>
                                                                <div className="accordion-animasyon-sarici">
                                                                    <div className='accordion-animasyonu'>
                                                                        <div className='accordion-donusum-sarici'>
                                                                            <div className='accordion-icerigi-alt'>
                                                                            <p>{t('accordionStrings:iAmATranslationEditorProofreaderBodyS')}</p>
                                                                                <Link to={t('accordionStrings:iAmATranslationEditorProofreaderURL')}>
                                                                                <button className='redBackgroundCls' type='button'>{t('accordionStrings:signUpButtonS')} </button>
                                                                                </Link>
                                                                                <span> {t('accordionStrings:yadaS')} </span>
                                                                                <Link to={t('accordionStrings:signInURL')}>
                                                                                <button className='redBackgroundCls' type='button'>{t('accordionStrings:signInButton')} </button>
                                                                                </Link>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="accordionItemCls">
                                                            <input id="accordionItem-tetik-19" className="accordionItem-tetik-girisi" type="checkbox"></input>
                                                            <label className="accordion-tetiki" htmlFor="accordionItem-tetik-19">
                                                            {t('accordionStrings:iAmADTPSpecialistTitleS')} 
                                                            <FaAngleLeft/>
                                                            {/* <AnimatedFaAngleLeft
                                                            className='showInlineBlockCls'
                                                            variants={shakeVariant}
                                                                initial="initial"
                                                                animate="shake" /> */}
                                                            </label>
                                                                <div className="accordion-animasyon-sarici">
                                                                    <div className='accordion-animasyonu'>
                                                                        <div className='accordion-donusum-sarici'>
                                                                            <div className='accordion-icerigi-alt'>
                                                                            <p>{t('accordionStrings:iAmADTPSpecialistBodyS')}</p>
                                                                                <Link to={t('accordionStrings:iAmADTPSpecialistURL')}>
                                                                                <button className='redBackgroundCls' type='button'>{t('accordionStrings:signUpButtonS')} </button>
                                                                                </Link>
                                                                                <span> {t('accordionStrings:yadaS')} </span>
                                                                                <Link to={t('accordionStrings:signInURL')}>
                                                                                <button className='redBackgroundCls' type='button'>{t('accordionStrings:signInButton')} </button>
                                                                                </Link>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="accordionItemCls">
                    <input id="accordionItem-tetik-20" className="accordionItem-tetik-girisi" type="checkbox"></input>
                    <label className="accordion-tetiki" htmlFor="accordionItem-tetik-20">
                    {t('accordionStrings:weAreATranslationBusinessTitleS')} 
                    <FaAngleLeft/>
                    {/* <AnimatedFaAngleLeft
                    className='showInlineBlockCls'
                    variants={shakeVariant}
                        initial="initial"
                        animate="shake" /> */}
                    </label>
                        <div className="accordion-animasyon-sarici">
                            <div className='accordion-animasyonu'>
                                <div className='accordion-donusum-sarici'>
                                    <div className='accordion-icerigi-alt'>
                                        <p>{t('accordionStrings:weAreATranslationBusinessBodyS')}</p>
                                            <Link to={t('accordionStrings:weAreATranslationBusinessURL')}>
                                            <button className='redBackgroundCls' type='button'>{t('accordionStrings:signUpButtonS')} </button>
                                            </Link>
                                            <span> {t('accordionStrings:yadaS')} </span>
                                            <Link to={t('accordionStrings:signInURL')}>
                                            <button className='redBackgroundCls' type='button'>{t('accordionStrings:signInButton')} </button>
                                            </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="accordionItemCls">
                    <input id="accordionItem-tetik-21" className="accordionItem-tetik-girisi" type="checkbox"></input>
                    <label className="accordion-tetiki" htmlFor="accordionItem-tetik-21">
                    {t('accordionStrings:weAreATranslationAssociationTitleS')} 
                    <FaAngleLeft/>
                    {/* <AnimatedFaAngleLeft
                    className='showInlineBlockCls'
                    variants={shakeVariant}
                        initial="initial"
                        animate="shake" /> */}
                    </label>
                        <div className="accordion-animasyon-sarici">
                            <div className='accordion-animasyonu'>
                                <div className='accordion-donusum-sarici'>
                                    <div className='accordion-icerigi-alt'>
                                        <p>{t('accordionStrings:weAreATranslationAssociationBodyS')}</p>
                                            <Link to={t('accordionStrings:weAreATranslationAssociationURL')}>
                                            <button className='redBackgroundCls' type='button'>{t('accordionStrings:signUpButtonS')} </button>
                                            </Link>
                                            <span> {t('accordionStrings:yadaS')} </span>
                                            <Link to={t('accordionStrings:signInURL')}>
                                            <button className='redBackgroundCls' type='button'>{t('accordionStrings:signInButton')} </button>
                                            </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </section>
    )
}

export default AccordionComp