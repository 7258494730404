import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { NavLink, useNavigate } from 'react-router-dom';

import AnimasyonluKarakterler from './AnimasyonluKarakterler';
import { EMAIL_REGEX, SIFRE_REGEX } from '../yararli/utils';
import { useGiris } from '../kancalar/girisKullan';
import YonlendirenBilesen from './YonlendirenBilesen';

const Signin = () => {
    const { t } = useTranslation()
    // const yonlendir = useNavigate()
    const aciklamaMesaji = t('messages:passwordMinLengthS:line1')+' - '+t('messages:passwordMinLengthS:line2') +' - '+t('messages:passwordMinLengthS:line3')
    const {
        register, 
        handleSubmit, 
        // watch,
        // setError, 
        formState: 
        {errors, 
            // isSubmitting
        } 
    } = useForm({
        mode: 'onChange'
    })

    const { girisYap, yukluyor, sifreSifirla, yonlendirilecekURL } = useGiris()

    /* useEffect(() => {
        yonlendir(yonlendirilecekURL)
    }, [yonlendirilecekURL])
    console.log("Yukarıya yonlendir ekledim. Ama emin değilim") */

    const submitSignIn = async (data) => {
        try {
            girisYap(data)
            return
        } catch (err) {
            console.log("catch err: ", err)
            if (err.message.includes('NetworkError')){
            }
        }
    }
    const onError = (errors) => {
        // setHataMesaji(`Hata on Errordan: ${errors}`)
        console.log("errors: ", errors)
    }

    return (
        <section className='centerCls'>
        <YonlendirenBilesen/>
            <h1>{t('forms:loginFormFieldS')}</h1>
            <form 
            className='formCls' 
            onSubmit={handleSubmit(submitSignIn, onError)}
            noValidate
            >
            {/* {hataMesaji && <div>{t("flashMessages:errorS")}{ hataMesaji}</div>} */}
                <div className="formControlCls">
                    <label htmlFor='email'>{t('forms:useremailFormFieldS')}</label>
                    <input 
                        type='email'
                        name='email'
                        id='email'
                        // ref={emailRef}
                        {...register(
                            "email", {
                                required: {value: true, 
                                message: t('messages:thisFieldIsRequiredS')},
                                pattern: {
                                    value: EMAIL_REGEX,
                                    message: t('messages:enterAValidEmailS')
                                    }
                            }
                        )}
                    />
                    {errors.email && <div className='redSpanCls'>{t('messages:enterAValidEmailS')} </div>}
                </div>
                <div className="formControlCls">
                <label htmlFor='sifre'>
                {t('forms:userpasswordFormFieldS')} 
                        </label>
                        <input
                            type='password'
                            id='sifre'
                            // onChange={(e) => setSifre(e.target.value)}
                            {...register(
                                "sifre", {
                                required: {value: true, 
                                message: t('messages:enterAPasswordS')},
                                pattern: {
                                    value: SIFRE_REGEX,
                                    message: aciklamaMesaji
                                }
                            }
                            )}
                        />
                    {errors.sifre && <div className='redSpanCls'>{aciklamaMesaji} <AnimasyonluKarakterler /></div>}
                </div>
                <div className='formControlCls'>
                        <button className='greenBackgroundCls roundedCornersCls paddingSides5Rem marginSides5Rem' 
                        // disabled={!gecerliEposta || !gecerliSifre || !sifrelerUyuyor ? true : false}
                        >{yukluyor ? t('forms:waitFormS') : t('forms:loginFormFieldS')}
                        </button>
                </div>
            </form>
            <p className={sifreSifirla ? '' : 'hideItCls'}>
                <NavLink className='redspanCls' to='../sifresifirlamatalepet'>{t('forms:forgotpasswordAndWantToResetS')}
                </NavLink>
            </p>
        </section>
    )
}

export default Signin;