import { useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

import { useKimlikDogrulamaBaglami } from '../kancalar/kullaniciBaglamiKullan'
import { useGenelAyarlarBaglami } from "../baglam/GenelAyarlarSaglayici";

export const useEpostaDogrulama = () => {
    const { t } = useTranslation();
    const [ yonlendirilecekURL] = useState('')
    const [ yukluyor, setYukluyor] = useState(false)
    const { dispatch, kullanici } = useKimlikDogrulamaBaglami()
    const { kabulEdilenDil, setBeklemekte, genelAyarlarYonlendirilecekURL, setGenelAyarlarYonlendirilecekURL } = useGenelAyarlarBaglami()
    // const { BASEURL} = useGenelAyarlarBaglami();

    const epostaDogrula = async (data) => {
        try {
            setYukluyor(true)
            // console.log("kabulEdilenDil: ", kabulEdilenDil)
            // console.log("document.cookie güvenli mi?", document.cookie)
            // console.log("fetch öncesi API_URLSI: ", BASEURL)
            setBeklemekte({beklemeMesaji: t("flashMessages:YourEmailBeingVerifiedFS"),
                beklesin: true})
            const yanit = await fetch(`/emaildogrulama`, {
                credentials: 'include',
                method: "POST",
                headers: {
                    "access-control-allow-origin" : "*",
                    "Accept": "application/json",
                    "Content-type": "application/json; charset=UTF-8",
                    "Authorization": "Bearer " + kullanici.isaret,
                    "Accept-Language": kabulEdilenDil,
                    "Content-Language": kabulEdilenDil
                },
                body: JSON.stringify(data)
            })
            if (!yanit.ok) {
                // console.log("yanit: ", yanit)
                if(yanit.status === 401){
                    toast.error(t("flashMessages:YourEmailVerificatiobCodeExpiredFS"))
                }
                else if(yanit.status === 403){
                    toast.error(t("flashMessages:YourEmailCannotBeVerifiedFS"))
                }
                else if(yanit.status === 500){
                    toast.error(t("flashMessages:server500ErrorFS"))
                }
                setYukluyor(false)
                setBeklemekte({beklemeMesaji: t("flashMessages:YourEmailBeingVerifiedFS"),
                    beklesin: false})
            }
            // const isaret = yanit.headers.get('authorization')?.split(" ")[1] || ''
            let veri = await yanit.json()
            if (veri.sonuc === "Eposta Dogrulama Basarili"){
                localStorage.removeItem('kullanici')
                // çıkış yap
                dispatch({type: 'SIGNOUT', payload: null})
                toast
                .success(t("flashMessages:YourEmailVerifiedFS"))
                setYukluyor(false)
                setBeklemekte({beklemeMesaji: t("flashMessages:YourEmailBeingVerifiedFS"),
                    beklesin: false})
                setGenelAyarlarYonlendirilecekURL((p) => p = '/')
                // setYonlendirilecekURL((y) => y = veri.kullanicininYonlendirilecegiUrl)
            }
        } catch (err) {
            console.log(err)
            setYukluyor(false)
            setBeklemekte({beklemeMesaji: t("flashMessages:YourEmailBeingVerifiedFS"),
                beklesin: false})
            setGenelAyarlarYonlendirilecekURL((p) => p = '/')
        }
    }

    return { epostaDogrula, yukluyor, yonlendirilecekURL }
}