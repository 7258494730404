import React, { useEffect } from 'react'
import { useKimlikDogrulamaBaglami } from '../kancalar/kullaniciBaglamiKullan'
import { useParams } from 'react-router-dom'

import YonlendirenBilesen from './YonlendirenBilesen'
import ProfilGuncelleme from './ProfilGuncelleme'
import { useProfilGetir } from '../kancalar/profilGetirKullan'

const Hesabim = () => {
    const { kullanici } = useKimlikDogrulamaBaglami()
    const { setEditMode } = useProfilGetir()
    const { editMode } = useParams()

    useEffect(() => {
        if(editMode === "edit") setEditMode(true)
    }, [])
    return (
        <>
        <YonlendirenBilesen/>
        {kullanici && <ProfilGuncelleme />}
        </>
    )
}

export default Hesabim