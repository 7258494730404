import { useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

import { useKimlikDogrulamaBaglami } from '../kancalar/kullaniciBaglamiKullan'
import { useGenelAyarlarBaglami } from "../baglam/GenelAyarlarSaglayici";

export const useKayit = () => {
    const { t } = useTranslation();
    const [ sifreSifirla, setSifreSifirla] = useState(false)
    const [ yukluyor, setYukluyor] = useState(false)
    const [ yonlendirilecekURL, setYonlendirilecekURL] = useState('')
    const { dispatch } = useKimlikDogrulamaBaglami()
    const { kabulEdilenDil, setBeklemekte } = useGenelAyarlarBaglami()
    // const { BASEURL} = useGenelAyarlarBaglami();

    const kayitOl = async (data) => {
        try {
            setYukluyor(true)
            setBeklemekte({beklemeMesaji: t("flashMessages:KayitOluyorFS"),
                beklesin: true})
            if(data.sifre !== data.teyitSifresi) {
                toast.error(t("flashMessages:PasswordsDoNotMatchFS"))
                setYukluyor(false)
                setBeklemekte({beklemeMesaji: t("flashMessages:KayitOluyorFS"),
                    beklesin: false})
                return 
            }
            // console.log("kabulEdilenDil: ", kabulEdilenDil)
            const yanit = await fetch(`/signupapi`, {
            // const yanit = await fetch(BASEURL+`/signupapi`, {
                credentials: 'include',
                method: "POST",
                headers: {
                    "access-control-allow-origin" : "*",
                    "Accept": "application/json",
                    "Content-type": "application/json; charset=UTF-8",
                    "Accept-Language": kabulEdilenDil,
                    "Content-Language": kabulEdilenDil
                },
                body: JSON.stringify(data)
            })
            if (!yanit.ok) {
                if(yanit.status === 401){
                    toast.error(t("flashMessages:PasswordsDoNotMatchFS"))
                }
                else if(yanit.status === 409){
                    toast.error(t("flashMessages:thisEmailInUseFS"))
                    setSifreSifirla(true)
                }
                else if(yanit.status === 500){
                    toast.error(t("flashMessages:server500ErrorFS"))
                }
                setYukluyor(false)
                setBeklemekte({beklemeMesaji: t("flashMessages:KayitOluyorFS"),
                    beklesin: false})
            }
            else { //(!yanit.ok) {
                console.log("kayıtkullan yanit: ", yanit)
                const isaret = yanit.headers.get('authorization')?.split(" ")[1] || ''
                let veri = await yanit.json()
                if (veri.sonuc === "Kayit Basarili"){
                        let sonVeri = {}
                        sonVeri["message"] = veri.message
                        sonVeri["username"] = veri.username
                        sonVeri["kullanici_slugi"] = veri.kullanici_slugi
                        sonVeri["toBeRedirectedTo"] = veri.toBeRedirectedTo
                        sonVeri["messageWithRedirectionTo"] = veri.messageWithRedirectionTo
                        sonVeri["profileUpdatedOn"] = veri.profileUpdatedOn
                        /* sonVeri["profilDuzenlemesiniErtele"] = veri.profilDuzenlemesiniErtele
                        sonVeri["ertelemeSuresi"] = veri.ertelemeSuresi */
                        sonVeri["isaret"] = isaret
                        localStorage.setItem('kullanici', JSON.stringify(sonVeri))
                        dispatch({type: 'SIGNUP', payload: sonVeri})
                        toast
                        .success(t("flashMessages:SuccessFullyRegisteredFS"))
                        setYukluyor(false)
                        setBeklemekte({beklemeMesaji: t("flashMessages:KayitOluyorFS"),
                            beklesin: false})
                        setYonlendirilecekURL((y) => y = veri.kullanicininYonlendirilecegiUrl)
                    }
            }
        } catch (err) {
            console.log(err)
            setYukluyor(false)
            setBeklemekte({beklemeMesaji: t("flashMessages:KayitOluyorFS"),
                beklesin: false})
        }
    }

    return { kayitOl, yukluyor, sifreSifirla, yonlendirilecekURL }
}