import { useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

import { useKimlikDogrulamaBaglami } from '../kancalar/kullaniciBaglamiKullan'
import { useGenelAyarlarBaglami } from "../baglam/GenelAyarlarSaglayici";

export const useGiris = () => {
    const { t } = useTranslation();
    const [ sifreSifirla, setSifreSifirla] = useState(false)
    const [ yonlendirilecekURL, setYonlendirilecekURL] = useState('')
    const [ yukluyor, setYukluyor] = useState(false)
    const { dispatch } = useKimlikDogrulamaBaglami()
    const { kabulEdilenDil, setBeklemekte, 
        genelAyarlarYonlendirilecekURL, 
        setGenelAyarlarYonlendirilecekURL, 
        genelAyarlarSimdikiAn, 
        setGenelAyarlarSimdikiAn,
        genelAyarlarGuncelemeTarihi, 
        setGenelAyarlarGuncelemeTarihi,
    } = useGenelAyarlarBaglami()
    // const { BASEURL} = useGenelAyarlarBaglami();

    const girisYap = async (data) => {
        try {
            setYukluyor(true)
            setBeklemekte({beklemeMesaji: t("flashMessages:GirisYapiyorFS"),
                beklesin: true})
            // console.log("kabulEdilenDil: ", kabulEdilenDil)
            // console.log("fetch öncesi API_URLSI: ", BASEURL)
            const yanit = await fetch(`/signinapi`, {
            // const yanit = await fetch(BASEURL+`/signinapi`, {
                credentials: 'include',
                method: "POST",
                headers: {
                    "access-control-allow-origin" : "*",
                    "Accept": "application/json",
                    "Content-type": "application/json; charset=UTF-8",
                    "Accept-Language": kabulEdilenDil,
                    "Content-Language": kabulEdilenDil
                },
                body: JSON.stringify(data)
            })
            const veri = await yanit.json()
            if (!yanit.ok) {
                console.log("veri: ", veri)
                if(yanit.status === 401){
                    if(veri.sonuc === "Kayda Yonlendir"){
                        // return <Navigate to={`/signup/${veri.email}`}/>
                        toast.error(t("flashMessages:UserCannotBeFoundFS"))
                        // setYonlendirilecekURL((y) => y = `/signup/${veri.email}`)
                        setGenelAyarlarYonlendirilecekURL((y) => y = `/signup/${veri.email}`)
                    }
                    if(veri.sonuc === "Sifre Sifirla"){
                        localStorage.removeItem('kullanici')
                        toast.error(t("flashMessages:YourPassWordMustBeResetFS"))
                        dispatch({type: 'SIGNOUT', payload: null})
                        // setSifreSifirla((ss) => true)
                        console.log("Burada sadece bildirimde bulun")
                    }
                    else {
                        toast.error(t("flashMessages:UserOrPasswordIsWrongFS"))
                        setSifreSifirla((ss) => true)
                    }
                }
                else if(yanit.status === 403){
                    toast.error(t("flashMessages:UserOrPasswordIsWrongFS"))
                    setSifreSifirla((ss) => true)
                }
                else if(yanit.status === 500){
                    toast.error(t("flashMessages:server500ErrorFS"))
                }
                setYukluyor(false)
                setBeklemekte({beklemeMesaji: t("flashMessages:GirisYapiyorFS"),
                    beklesin: false})
            }
            const isaret = yanit.headers.get('authorization')?.split(" ")[1] || ''
            // console.log("login isaret: ", isaret)
            let sonVeri = {}
            // delete veri['sonuc']
            // let tmpVeri = veri.message
            sonVeri["message"] = veri.message
            sonVeri["username"] = veri.username
            sonVeri["kullanici_slugi"] = veri.kullanici_slugi
            sonVeri["toBeRedirectedTo"] = veri.toBeRedirectedTo
            sonVeri["messageWithRedirectionTo"] = veri.messageWithRedirectionTo
            sonVeri["profileUpdatedOn"] = veri.profileUpdatedOn
            // sonVeri["profilDuzenlemesiniErtele"] = veri.profilDuzenlemesiniErtele
            // sonVeri["ertelemeSuresi"] = veri.ertelemeSuresi
            sonVeri["isaret"] = isaret
            // sonVeri = {...sonVeri, isaret }
            if (veri.sonuc === "Giris Basarili"){
                localStorage.setItem('kullanici', JSON.stringify(sonVeri))
                // bağlama ekle
                dispatch({type: 'SIGNIN', payload: sonVeri})
                toast
                .success(t("flashMessages:SuccessFullyLoggedInFS"))
                setYukluyor(false)
                setBeklemekte({beklemeMesaji: t("flashMessages:GirisYapiyorFS"),
                    beklesin: false})
                // setYonlendirilecekURL((y) => y = '/')
                setGenelAyarlarSimdikiAn((sa) => sa = +new Date())
                setGenelAyarlarGuncelemeTarihi((gt) => gt = +new Date(veri.profileUpdatedOn))
                // const guncellemeTarihi = +new Date(veri.profileUpdatedOn)
                console.log("girisKullan genelAyarlarGuncelemeTarihi: ", genelAyarlarGuncelemeTarihi, 
                    "\ngenelAyarlarSimdikiAn: ", genelAyarlarSimdikiAn,
                    "\ngenelAyarlarGuncelemeTarihi - genelAyarlarSimdikiAn: ",
                    ((genelAyarlarGuncelemeTarihi - genelAyarlarSimdikiAn) / (1000 * 60 * 60 * 24), " gün"))
                if(veri.toBeRedirectedTo !== ''){
                    console.log("girisKullan if(veri.toBeRedirectedTo !== ''){")
                    if (genelAyarlarGuncelemeTarihi === 0) {
                        console.log("girisKullan if (guncellemeTarihi === 0) {")
                        setGenelAyarlarYonlendirilecekURL((y) => y = '/hesabim')
                    }
                    else if (( genelAyarlarSimdikiAn - genelAyarlarGuncelemeTarihi) > (1000 * 60 * 60 * 24 * 30)) {
                        console.log("girisKullan else if (guncellemeTarihi < +genelAyarlarSimdikiAn + (1000 * 60 * 60 * 24 * 30)) {")
                        setGenelAyarlarYonlendirilecekURL((y) => y = '/hesabim')
                    }
                    else {
                        console.log("girisKullan else")
                        setGenelAyarlarYonlendirilecekURL((y) => y = '/')
                    }
                }
                else {
                    setGenelAyarlarYonlendirilecekURL((y) => y = '/')
                }
            }
        } catch (err) {
            console.log(err)
            setYukluyor(false)
            setBeklemekte({beklemeMesaji: t("flashMessages:GirisYapiyorFS"),
                beklesin: false})
        }
    }

    return { girisYap, yukluyor, sifreSifirla, yonlendirilecekURL }
}