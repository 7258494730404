import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';

i18n
// .use(initReactI18next)
.use(LanguageDetector)
.use(initReactI18next)
.use(HttpApi)
.init({
    supportedLngs: ['tr', 'en'],
    lng: 'tr',
    fallback: 'tr',
    detection: {
        order: ['path', 
        'cookie', 
        'localStorage',
        'sessionStorage',
        'navigator',
        'htmlTag',
        'subdomain'],
        lookupFromPathIndex: 0,
        checkWhitelist: true,
        caches: ['cookie'],
        cookieMinutes: 60 * 24 * 7,
        cookieOptions: {path: '/', sameSite: "strict", maxAge: 60 * 60 * 24 * 7},
    },
    backend: {
        loadPath: '/assets/locales/{{lng}}/{{ns}}/translation.json',
    },
    ns: [
    "genericStrings",
    "accordionStrings",
    "genericogMetaTags",
    "footerStrings",
    "sosyalMedya",
    "regularMenus",
    "linguisticallyReverseMenuItems",
    "adminSpecificMenus",
    "forms",
    "messages",
    "flashMessages",
    "toolTips",
    "messages"],
});

export default i18n;