import React, { createContext, useEffect, useReducer } from "react";

export const KimlikDogrulamaBaglami = createContext({});

// const yerelDepodan = localStorage.getItem("kullanici")
// console.log("yerelDepodan: ", yerelDepodan)

const initialState = {
    kullanici: {},
}

// console.log("KimlikDogrulamaBaglami initialState: ", JSON.stringify(initialState))

export const kimlikReducer = (state, action) => {
    switch (action.type) {
        case "SIGNUP":
            return {
                // ...state,
                kullanici: action.payload,
            }
        case "SIGNIN":
            return {
                // ...state,
                kullanici: action.payload,
            }
        case "EMAIL_VERIFIED":
            return {
                // ...state,
                kullanici: action.payload,
            }
        case "PROFILE_UPDATED":
            return {
                // ...state,
                kullanici: action.payload,
            }
        case "REDIRECT":
            return {
                // ...state,
                kullanici: action.payload,
            }
        case "SIGNOUT":
            return {
                // ...state,
                kullanici: null,
            }
        default:
            return state
    }
}

export const KimlikDogrulamaSaglayici = ({ children }) => {
    
    const [
        state,
        dispatch] = useReducer(kimlikReducer, initialState)

    // console.log("KimlikDogrulamaSaglayici içi state : ", JSON.stringify(state))
    // console.log(dispatch)

    useEffect(() => {
        // state = {...state, JSON.parse(localStorage.getItem("kullanici"))}
        state.kullanici = JSON.parse(localStorage.getItem("kullanici"))
        // console.log("kimlik bağlamı useeffect state:", JSON.stringify(state))
        // const { kullanici } = state
        if(state) {
            dispatch({type: "SIGNIN", payload: state.kullanici}) //zaten giriş yapmış anlamında
        }
        // console.log("kullanici bağlamı useeffect kontrolden sonra state:", JSON.stringify(state))
    }, [])

    return (
        <KimlikDogrulamaBaglami.Provider value={
                {
                    ...state,
                    dispatch
                }
            }>
            { children }
        </KimlikDogrulamaBaglami.Provider>
    )
}

export default KimlikDogrulamaBaglami; //bunu kullanmak zorunda mısın? Değilsen çıkart bunu