import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useGenelAyarlarBaglami } from '../baglam/GenelAyarlarSaglayici'
import { useNavigate } from 'react-router-dom'
import { DevTool } from '@hookform/devtools'
import { useSifreSifirlamaTalepEt } from '../kancalar/sifreSifirlamaTalepEtKullan'

import YonlendirenBilesen from './YonlendirenBilesen'
import { EMAIL_REGEX } from '../yararli/utils'

const SifreSifirlamaTalebi = () => {
    const { t } = useTranslation()
    const { demoState} = useGenelAyarlarBaglami()
    // const yonlendir = useNavigate()

    const {
        register, 
        handleSubmit, 
        control,
        // setError,
        // watch,
        formState: 
        {errors, 
            // isSubmitting
        } 
    } = useForm ({
        mode: "onChange"
    })

    const onError = (errors) => {
        // setHataMesaji(`Hata on Errordan: ${errors}`)
        console.log("errors: ", errors)
    }

    const { sifreSifirlamaTaleptEt, yonlendirilecekURL, yukluyor } = useSifreSifirlamaTalepEt()
    /* useEffect(() => {
            yonlendir(yonlendirilecekURL)
        }, [yonlendirilecekURL]) */


    return (
        <section className='centerCls'>
            <YonlendirenBilesen/>
            <h1>{t('genericStrings:resetPasswordS')}</h1>
            <form 
            className='formCls' 
            onSubmit={handleSubmit(sifreSifirlamaTaleptEt, onError)}
            noValidate
            >
            DOĞRU SUBMITI EKLE
                <div className='formControlCls'>
                    <label htmlFor='email'>{t('forms:useremailFormFieldS')} 
                    </label>
                    <input
                        type='email'
                        id='email'
                        {...register(
                            "email", {
                                required: {
                                    value: true, 
                                    message: t('messages:thisFieldIsRequiredS')
                                },
                                pattern: {
                                    value: EMAIL_REGEX,
                                    message: t('messages:enterAValidEmailS')
                                    }
                            }
                        )}
                    />
                    {errors.email && <div className='redSpanCls'>{errors.email?.message} </div>}
                </div>
                <div className='formControlCls'>
                        <button className={(errors.teyitSifresi || errors.sifre || errors.sifirlamaKodu) ? 
                        'redBackgroundCls roundedCornersCls paddingSides5Rem marginSides5Rem' : 
                        'greenBackgroundCls roundedCornersCls paddingSides5Rem marginSides5Rem'}
                        disabled={errors.teyitSifresi || errors.sifre || errors.sifirlamaKodu}
                        >
                        {yukluyor ? t('forms:waitFormS') : t('forms:requestPasswordResetCodeS')}
                        </button>
                </div>
            </form>
            { demoState && <DevTool control={control}/> }
        </section>
    )
}

export default SifreSifirlamaTalebi