import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useKimlikDogrulamaBaglami } from '../kancalar/kullaniciBaglamiKullan'
import { useGenelAyarlarBaglami } from '../baglam/GenelAyarlarSaglayici'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'

const YonlendirenBilesen = () => {
        const { kullanici } = useKimlikDogrulamaBaglami()
        const { t } = useTranslation()
        // const [ guncellemeTarihi, setGuncellemeTarihi ] = useState()
        // const [ simdikiAn, setSimdikiAn ] = useState(new Date())
        const { beklemekte, setYonlendirilmekte, genelAyarlarYonlendirilecekURL, setGenelAyarlarYonlendirilecekURL } = useGenelAyarlarBaglami()
        const yonlendir = useNavigate()
        const gecerliKonumURL = useLocation()

        useEffect(() => {
            console.log("Yönlendirme genelAyarlarYonlendirilecekURL: ", genelAyarlarYonlendirilecekURL)
            yonlendir(genelAyarlarYonlendirilecekURL)
            return 
            setGenelAyarlarYonlendirilecekURL((p) => p = '')
            setYonlendirilmekte(true)
            console.log("Yönlendirme beklemekte: ", beklemekte)
            console.log("Yönlendirme kullanici?.username: ", kullanici)
            console.log("Yönlendirme gecerliKonumURL: ", gecerliKonumURL)
            if((kullanici.username === null)
                /* && (!gecerliKonumURL?.pathname.includes('signin'))
                && (!gecerliKonumURL?.pathname.includes('signup'))
                && (!gecerliKonumURL?.pathname.includes('sifresifirlamatalepet'))
                && (!gecerliKonumURL?.pathname.includes('sifresifirla'))
                && (!gecerliKonumURL?.pathname.includes('sifresifirla'))
                && (!gecerliKonumURL?.pathname.includes('hesabim')) */
            ){
                console.log("Kullanıcı var gecerliKonumURL?.pathname: ", gecerliKonumURL?.pathname)
                return yonlendir("/")
            }
            else { // if((kullanici?.username !== '') 
                console.log("Kullanıcı yok gecerliKonumURL?.pathname: ", gecerliKonumURL?.pathname)
                if(
                    (gecerliKonumURL?.pathname.includes('hesabim')) ||
                    // (gecerliKonumURL?.pathname.includes('videoodasi')) ||
                    (gecerliKonumURL?.pathname.includes('emaildogrulama'))
                ){
                    toast.error(t("flashMessages:PleaseLogInFS"))
                    return yonlendir("/")
                }
                /*if(gecerliKonumURL?.pathname.includes(genelAyarlarYonlendirilecekURL)){
                    console.log("Buraya geliyor mu yönlendirmede")
                    // yonlendir(genelAyarlarYonlendirilecekURL)
                }
                else {
                    yonlendir(genelAyarlarYonlendirilecekURL)
                }*/
            } //else { // if((kullanici?.username !== '') 
            // setSimdikiAn((sa) => sa = +(new Date()))
            setYonlendirilmekte(false)
            return () => {setYonlendirilmekte(false)}
        // })
        }, [genelAyarlarYonlendirilecekURL])

    return (
        <></>
    )
}

export default YonlendirenBilesen