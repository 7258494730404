import React from 'react'
import { useTranslation } from "react-i18next";

import './telifbildirimi.css'

function TelifBildirimi() {
    const { t } = useTranslation();
    const currentYear = new Date(Date.now()).getYear()+1900
    return (
        <div className='telifBildirimiCls'>
            <small>
            {t('footerStrings:copyrightNoticeFooter')} {currentYear} <a target='_blank' rel="noreferrer" href={t('genericStrings:dev_url')} className='redspanCls'> {t('genericStrings:dev_name')}</a>
            </small>
        </div>
    )
}

export default TelifBildirimi