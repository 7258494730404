import { useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

import { useKimlikDogrulamaBaglami } from '../kancalar/kullaniciBaglamiKullan'
import { useGenelAyarlarBaglami } from "../baglam/GenelAyarlarSaglayici";

export const useSifreSifirlamaTalepEt = () => {
    const { t } = useTranslation();
    const [ yonlendirilecekURL, setYonlendirilecekURL] = useState('')
    const [ yukluyor, setYukluyor] = useState(false)
    const { dispatch } = useKimlikDogrulamaBaglami()
    const { kabulEdilenDil, setBeklemekte, setGenelAyarlarYonlendirilecekURL } = useGenelAyarlarBaglami()
    // const { BASEURL} = useGenelAyarlarBaglami();

    const sifreSifirlamaTaleptEt = async (data) => {
        try {
            setYukluyor(true)
            setBeklemekte({beklemeMesaji: t("flashMessages:SifreSifirlamaTalebiGonderiliyorFS"),
                beklesin: true})
            // console.log("kabulEdilenDil: ", kabulEdilenDil)
            // console.log("document.cookie güvenli mi?", document.cookie)
            // console.log("fetch öncesi API_URLSI: ", BASEURL)
            const yanit = await fetch(`/sifresifirlamatalepet`, {
            // const yanit = await fetch(BASEURL+`/signinapi`, {
                method: "POST",
                headers: {
                    "access-control-allow-origin" : "*",
                    "Accept": "application/json",
                    "Content-type": "application/json; charset=UTF-8",
                    "Accept-Language": kabulEdilenDil,
                    "Content-Language": kabulEdilenDil
                },
                body: JSON.stringify(data)
            })
            const veri = await yanit.json()
            if (!yanit.ok) {
                console.log("yanit: ", yanit)
                setYukluyor(false)
                setBeklemekte({beklemeMesaji: t("flashMessages:SifreSifirlamaTalebiGonderiliyorFS"),
                    beklesin: false})
                if(yanit.status === 401){
                    if(veri.sonuc === "Kayda Yonlendir"){
                        // return <Navigate to={`/signup/${veri.email}`}/>
                        toast.error(t("flashMessages:UserCannotBeFoundFS"))
                        // setYonlendirilecekURL((y) => y = `/signup/${data.email}`)
                        setGenelAyarlarYonlendirilecekURL((y) => y = `/signup/${data.email}`)
                    }
                }
                else if(yanit.status === 500){
                    toast.error(t("flashMessages:server500ErrorFS"))
                }
            }
            // const isaret = yanit.headers.get('authorization')?.split(" ")[1] || ''
            // let veri = await yanit.json()
            if (veri.sonuc === "Sifre Degistirebilirsiniz"){
                localStorage.removeItem('kullanici')
                // dispatch({type: 'SIGNOUT', payload: null})
                toast
                .success(t("flashMessages:YourPassWordMustBeResetFS"))
                setYukluyor(false)
                setBeklemekte({beklemeMesaji: t("flashMessages:SifreSifirlamaTalebiGonderiliyorFS"),
                    beklesin: false})
                setGenelAyarlarYonlendirilecekURL((y) => y = `/sifresifirla/${data.email}`)
                // setYonlendirilecekURL((y) => y = `/sifresifirla/${data.email}`)
            }
        } catch (err) {
            console.log(err)
            setYukluyor(false)
            setBeklemekte({beklemeMesaji: t("flashMessages:SifreSifirlamaTalebiGonderiliyorFS"),
                beklesin: false})
        }
    }

    return { sifreSifirlamaTaleptEt, yukluyor, yonlendirilecekURL }
}