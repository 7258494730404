import React, { useEffect } from 'react'
import { useKimlikDogrulamaBaglami } from '../kancalar/kullaniciBaglamiKullan'

import YonlendirenBilesen from '../Bilesenler/YonlendirenBilesen'

const VideoOdasi = () => {
    const { kullaniciDogrulanmis } = useKimlikDogrulamaBaglami()


    return (
        <section className='centerCls'>
        <YonlendirenBilesen />
        <div className='videoOdasiCls'>
            <h1>Test</h1>
        </div>
        </section>
    )
    /* return kullaniciDogrulanmis ? (
        <>
        <YonlendirenBilesen/>
        <div className='videoOdasiCls'>
            <h1>Test</h1>
        </div>
        </>
    ) : null */
}

export default VideoOdasi