import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next'
import { DevTool } from '@hookform/devtools';
import { useGenelAyarlarBaglami } from '../baglam/GenelAyarlarSaglayici';
import toast from 'react-hot-toast';
import { Navigate, useNavigate } from 'react-router-dom';

import YonlendirenBilesen from './YonlendirenBilesen'
import { EMAIL_REGEX, SIFRE_REGEX } from '../yararli/utils';
import AnimasyonluKarakterler from './AnimasyonluKarakterler';
import { useSifreSifirlama } from '../kancalar/sifreSifirlaKullan';
import { useKimlikDogrulamaBaglami } from '../kancalar/kullaniciBaglamiKullan';

const SifreSifirla = () => {
    const { t } = useTranslation()
    const { demoState} = useGenelAyarlarBaglami()
    // const yonlendir = useNavigate()

    const {
        register, 
        handleSubmit, 
        control,
        setError,
        watch,
        formState: 
        {errors, 
            // isSubmitting
        } 
    } = useForm ({
        mode: "onChange"
    })

    const { sifreSifirla, yukluyor, yonlendirilecekURL }= useSifreSifirlama()
    const { kullanici } = useKimlikDogrulamaBaglami()


    useEffect(() => {
        toast.error(t("flashMessages:ResettingCodeSentFS"))
    }, [t])

    /* useEffect(() => {
        console.log("SifreSifirla yonlendirilecekURL: ", yonlendirilecekURL)
        if((yonlendirilecekURL !== '') && (yonlendirilecekURL === "undefined") && (yonlendirilecekURL === "<empty string>")){
            yonlendir(yonlendirilecekURL)
        }
    }, [yonlendirilecekURL])
    console.log("Yukarıya yonlendir ekledim. Ama emin değilim") */
    

    const submitChangePassWord = async (data) => {
        // console.log("signup data: ", data)
        try {
            if(errors.teyitSifresi || errors.sifre || errors.sifirlamaKodu){
                console.log("HOBAA")
                return
            }
            await sifreSifirla(data)
        } catch (err) {
            console.log(err)
        }
    }

    const onError = (errors) => {
        // setHataMesaji(`Hata on Errordan: ${errors}`)
        console.log("errors: ", errors)
    }

/*     if(kullanici?.toBeRedirectedTo === undefined){
            return <Navigate to="/"/>
        } */

    const sifreAciklamaMesaji = t('messages:passwordMinLengthS:line1')+' - '+t('messages:passwordMinLengthS:line2') +' - '+t('messages:passwordMinLengthS:line3')
    const teyitSifreAciklamaMesaji = t('flashMessages:PasswordsMustMatchFS')
    const sifre = watch("sifre")

    return (
        <section className='centerCls'>
            <YonlendirenBilesen/>
            <h1>{t('genericStrings:resetPasswordS')}</h1>
            <form 
            className='formCls' 
            onSubmit={handleSubmit(submitChangePassWord, onError)}
            noValidate
            >
                <div className='formControlCls'>
                    <label htmlFor='email'>{t('forms:useremailFormFieldS')} 
                    </label>
                    <input
                        type='email'
                        id='email'
                        {...register(
                            "email", {
                                required: {
                                    value: true, 
                                    message: t('messages:thisFieldIsRequiredS')
                                },
                                pattern: {
                                    value: EMAIL_REGEX,
                                    message: t('messages:enterAValidEmailS')
                                    }
                            }
                        )}
                    />
                    {errors.email && <div className='redSpanCls'>{errors.email?.message} </div>}
                </div>
                <div className="formControlCls">
                <h1>ŞİFRE SIFIRLAMA KODU YANLIŞ GİRİLİNCE NE OLUYOR ONU DA HALLET</h1>
                    <label htmlFor='sifirlamaKodu'>{t('forms:passwordResetCodeS')}</label>
                    <input 
                        type='text'
                        name='sifirlamaKodu'
                        id='sifirlamaKodu'
                        {...register(
                            "sifirlamaKodu", {
                                required: {value: true, 
                                message: t('messages:thisFieldIsRequiredS')},
                                minLength: {
                                    value: 60,
                                    message: t('messages:passwordResettingCodeMinLengthS')
                                }
                            }
                        )}
                    />
                    {errors.sifirlamaKodu && <div className='redSpanCls'>{t('messages:passwordResettingCodeMinLengthS')} </div>}
                </div>
                <div className='formControlCls'>
                <label htmlFor='sifre'>{t('forms:userpasswordFormFieldS')} 
                </label>
                <input
                    type='password'
                    id='sifre'
                    name='sifre'
                    disabled = {errors.sifirlamaKodu}
                    {...register(
                            "sifre", {
                            required:
                            {value: true, 
                            message: t('messages:enterAPasswordS')
                            },
                            pattern: {
                                value: SIFRE_REGEX,
                                message: sifreAciklamaMesaji
                            }
                        }
                    )}
                />
                {errors.sifre && <div className='redSpanCls'>{sifreAciklamaMesaji} <AnimasyonluKarakterler/> </div>}
            </div>
            <div className='formControlCls'>
                <label htmlFor='teyitSifresi'>{t('forms:userpasswordForConfirmationFormFieldS')}
                </label>
                <input
                    type='password'
                    id='teyitSifresi'
                    name='teyitSifresi'
                    disabled = {errors.sifirlamaKodu}
                    aria-describedby='teyitSifrenote'
                    {...register(
                            "teyitSifresi", {
                            required: 
                            {value: true, 
                            message: t('messages:confirmThePasswordS')
                            },
                            pattern: {
                                value: SIFRE_REGEX,
                                message: sifreAciklamaMesaji
                            },
                            validate: (val) => val === sifre || setError("teyitSifresi")
                        }
                    )}
                />
                {errors.teyitSifresi && <div className='redSpanCls'>{ teyitSifreAciklamaMesaji } {sifreAciklamaMesaji} <AnimasyonluKarakterler/> </div>}
            </div>
                <div className='formControlCls'>
                        <button className={(errors.teyitSifresi || errors.sifre || errors.sifirlamaKodu) ? 
                        'redBackgroundCls roundedCornersCls paddingSides5Rem marginSides5Rem' : 
                        'greenBackgroundCls roundedCornersCls paddingSides5Rem marginSides5Rem'}
                        disabled={errors.teyitSifresi || errors.sifre || errors.sifirlamaKodu}
                        >
                        {yukluyor ? t('forms:waitFormS') : t('forms:changePasswordS')}
                        </button>
                </div>
            </form>
            { demoState && <DevTool control={control}/> }
        </section>
    )
}

export default SifreSifirla