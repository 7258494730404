import React from 'react'
/* import { useDispatch, useSelector } from 'react-redux'
import { addUserToVideoRoom, videoOdasiDilimi, videoodasi } from '../VideoOdasi/videoOdasiDilimi'

import { soketSunucusunaBaglan } from '../soketBaglantisi/soketBaglantisi'  */
import './demo.css' 
console.log("Yukarıdaki kısmı devreye al")

function Demo() {
    return (
        <div className='demoDivCls'>
            <h1>YAPIM HALİNDE - UNDER CONSTRUCTION - В разработке! - Bajo construcción</h1>
            <h1>LÜTFEN KAYIT OLMAYIN - PLEASE DO NOT REGISTER - Не регистрируйтесь! - No te registres</h1>
        </div>
    )
}

export default Demo