import React, {useEffect, useRef, useState} from 'react'
import { motion, useAnimate } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { FaFacebook, FaTwitter} from 'react-icons/fa'

import './DropUpMenu.css'
import CloseButton from './CloseButton'

const DropUpMenu = () => {
    const { t } = useTranslation()
    const [sosyalMedyaKapsami, animate] = useAnimate();
    const [sagAltMenuAcik, setSagAltMenuAcik] = useState(false)
    const yukariAcilirMenuKalemleriRef = useRef()

    const divMetniniDegistir = async (str) => {
        // console.log("str: ", str)
        sosyalMedyaKapsami.current.innerHTML = str
    }

    let sosyalMedyaListesi = t('sosyalMedya:liste', {returnObjects: true})
    // console.log("sosyalMedya: ", sosyalMedyaListesi)
    useEffect(() => {
        sosyalMedyaListesi = t('sosyalMedya:liste', {returnObjects: true})
    }, [document.documentElement.lang])

    useEffect( () => {
            const animateButtons = async () =>{
                await divMetniniDegistir(t('footerStrings:paylasS'))
                await animate(sosyalMedyaKapsami.current, { y: ["110%", "0%"]}, {duration: 1})
                await divMetniniDegistir(t('footerStrings:begenS'))
                await animate(sosyalMedyaKapsami.current, { y: ["110%", "0%"]}, {duration: 1})
                await divMetniniDegistir(t('footerStrings:takipEtS'))
                await animate(sosyalMedyaKapsami.current, { y: ["110%", "0%"]}, {duration: 1})
                animateButtons()
            }
                animateButtons();
    }, [document.documentElement.lang])

    const ikonGetir = (str) => {
        if (str === "facebooksayfasi") 
        {
            return (<FaFacebook
                color='#00FFFF'
            />)
        }
        else if (str === "twitter") 
        {
            return (<FaTwitter
                color='#00FFFF'
            />)
        }
    }

    // console.log("sagAltMenuAcik : yukariAcilirMenuKalemleriniGoster öncesi", sagAltMenuAcik)
    const yukariAcilirMenuKalemleriniGoster = () => {
        setSagAltMenuAcik((sm) => !sm)
        // console.log("sagAltMenuAcik içeride: ", sagAltMenuAcik)
    }

    return (
        <div 
        className='sagAltKoseCls'
        onClick={yukariAcilirMenuKalemleriniGoster}
        >
            <div className='greenBackgroundCls'>
                <motion.div 
                ref={sosyalMedyaKapsami} 
                id='buttonAnimationID' 
                className='animationCls'
                // className={sagAltMenuAcik ? 'animationCls invisibleCls' : 'animationCls'}
                >
                </motion.div>
                <div 
                ref={yukariAcilirMenuKalemleriRef} 
                className={sagAltMenuAcik ? 'yukariAcilirMenuKalemleri greenBackgroundCls' : 'yukariAcilirMenuKalemleri greenBackgroundCls invisibleCls'}
                >
                <CloseButton sagaDayali={false} setTelefonDiyalogu={setSagAltMenuAcik}/>
                { sosyalMedyaListesi.map((eleman, dizin) => {
                    return (
                        <Link
                        to={eleman.begenmeBaglantisi}
                        target='_blank'
                        rel='noopener noreferrer'
                        className='yukariAcilirMenuKalemi'
                        key={dizin}
                        >
                        {ikonGetir(eleman.turu)}<span> </span>
                        {eleman.baslik}
                        </Link>
                    )
                })}
                </div>
            </div>
        </div>
    )
}

export default DropUpMenu