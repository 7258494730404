import React, { useEffect } from 'react'
import { motion, useAnimate } from 'framer-motion';
import { useTranslation } from 'react-i18next';

import './AnimasyonluKarakterler.css'
// import i18n from '../i18n';


const AnimasyonluKarakterler = () =>{
    const { t } = useTranslation();
    const [ karakterKapsami, animate ] = useAnimate();
    let karakterler = t('messages:passwordMinLengthS:info', {returnObjects: true})
    // console.log(karakterler)
    let spanlar = []
    // setSpans(() => {
        spanlar = karakterler.map((ele, diz) => {
            return (
            <motion.span 
            key={diz}
            // className='redSpanCls'
            id={`span${diz}`}
            aria-label={ele.description}
            >
            {ele.symbol}
            </motion.span>)
        })
    // })

    useEffect(() => {
        const animateSpans = async () =>{
            await animate([
                ["#span0", 
                {scale:[1, 3.5, 1]}, 
                {duration: 1}],
                ["#span1", 
                {scale:[1, 3.5, 1]}, 
                {duration: 1}],
                ["#span2", 
                {scale:[1, 3.5, 1]}, 
                {duration: 1}],
                ["#span3", 
                {scale:[1, 3.5, 1]}, 
                {duration: 1}],
                ["#span4", 
                {scale:[1, 3.5, 1]}, 
                {duration: 1}],
                ["#span5", 
                {scale:[1, 3.5, 1]}, 
                {duration: 1}],
                ["#span6", 
                {scale:[1, 3.5, 1]}, 
                {duration: 1}],
            ],
            {repeat:Infinity})
        }
        animateSpans();
    }, [animate])

    return (
        <div 
        className='relativePosCls'
        ref={karakterKapsami}
        >
        {spanlar}
        </div>
    )
}

export default AnimasyonluKarakterler