import { useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

import { useKimlikDogrulamaBaglami } from '../kancalar/kullaniciBaglamiKullan'
import { useGenelAyarlarBaglami } from "../baglam/GenelAyarlarSaglayici";
import { useIsaretTazele } from "./isaretTazeleKullan";

export const useProfilGetir = () => {
    const { t } = useTranslation();
    const [ yukluyor, setYukluyor] = useState(false)
    const [ editMode, setEditMode] = useState(false)
    const [ getirilenProfil, setGetirilenProfil] = useState()
    // const [ yonlendirilecekURL, setYonlendirilecekURL] = useState('')
    const { dispatch } = useKimlikDogrulamaBaglami()
    const { kabulEdilenDil, 
        setBeklemekte, 
        setGenelAyarlarYonlendirilecekURL,
        genelAyarlarSimdikiAn, 
        setGenelAyarlarSimdikiAn,
        genelAyarlarGuncelemeTarihi, 
        setGenelAyarlarGuncelemeTarihi, } = useGenelAyarlarBaglami()
    const {isaretTazele} = useIsaretTazele()

    const profilGetir = async (data) => {
        try {
            console.log("profilGetir başta editMode: ", editMode)
            setYukluyor(true)
            setBeklemekte({beklemeMesaji: t("flashMessages:ProfilCekiliyorFS"),
                beklesin: true})
            const isarettmp = data.isaret
            // console.log("profilGetir isarettmp: ", isarettmp)
            // console.log("profilGetir data.isaret: ", data.isaret)
            if(typeof(isarettmp) === "undefined"){
                console.log("profilGetir buraya geliyor mu")
                setYukluyor(false)
                setBeklemekte({beklemeMesaji: t("flashMessages:ProfilCekiliyorFS"),
                    beklesin: false})
                    setGenelAyarlarYonlendirilecekURL((p) => p = '/')
                return
            }
            const yanit = await fetch(`/hesabim`, {
            // const yanit = await fetch(BASEURL+`/signupapi`, {
                credentials: 'include',
                method: "POST",
                headers: {
                    "access-control-allow-origin" : "*",
                    "Accept": "application/json",
                    "Content-type": "application/json; charset=UTF-8",
                    "Authorization": "Bearer " + data.isaret,
                    "Accept-Language": kabulEdilenDil,
                    "Content-Language": kabulEdilenDil
                },
                body: ""
            })
            // console.log("profili getir ulan")
            // console.log("profilGetir yanit: ", yanit)
            const cekilenVeri = await yanit.json()
            if (!yanit.ok) {
                if(yanit.status === 401){
                    if(cekilenVeri.sonuc === "Yassah Ki Yassah") {
                        // toast.error(t("flashMessages:PleaseLogInFS"))
                        localStorage.removeItem('kullanici')
                        dispatch({type: 'SIGNOUT', payload: null})
                    }
                    else if ((cekilenVeri.sonuc === "Caylari Tazele") ){
                        // toast.error("OOOOOOO")
                        isaretTazele()
                    }
                    else if ((cekilenVeri.sonuc === "Tekrar Giris Yap") ){
                        localStorage.removeItem('kullanici')
                        dispatch({type: 'SIGNOUT', payload: null})
                    }
                    else if ((cekilenVeri.sonuc === "Sifre Sifirla") ){
                        localStorage.removeItem('kullanici')
                        dispatch({type: 'SIGNOUT', payload: null})
                    }
                    else {
                        toast.error(t("flashMessages:notAuthorizedToDoTheAction"))
                    }
                }
                else if(yanit.status === 500){
                    toast.error(t("flashMessages:server500ErrorFS"))
                }
                setYukluyor(false)
                setBeklemekte({beklemeMesaji: t("flashMessages:ProfilCekiliyorFS"),
                    beklesin: false})
            }
            else { //(!yanit.ok) {
                // console.log("profilGetir cekilenVeri.profilVerileri: ", cekilenVeri.profilVerileri)
                setGetirilenProfil(cekilenVeri.profilVerileri)
                setGenelAyarlarSimdikiAn((sa) => sa = +new Date())
                setGenelAyarlarGuncelemeTarihi((gt) => gt = +new Date(cekilenVeri.profilVerileri.profileUpdatedOn))
                setGenelAyarlarYonlendirilecekURL((p) => p = '/hesabim')
                console.log("profilGetir genelAyarlarGuncelemeTarihi: ", genelAyarlarGuncelemeTarihi, 
                    "\ngenelAyarlarSimdikiAn: ", genelAyarlarSimdikiAn,
                    "\ngenelAyarlarGuncelemeTarihi - genelAyarlarSimdikiAn: ",
                    ((genelAyarlarGuncelemeTarihi - genelAyarlarSimdikiAn) / (1000 * 60 * 60 * 24)))
                    if(cekilenVeri.profilVerileri.toBeRedirectedTo !== ''){
                        console.log("profilGetir if(veri.toBeRedirectedTo !== ''){")
                        if (genelAyarlarGuncelemeTarihi === 0) {
                            console.log("profilGetir if (guncellemeTarihi === 0) {")
                            toast.error(t("flashMessages:YourProfileIsIncompleteFS"))
                            toast.success(t("flashMessages:YouMustAtLeastChangeUsernameFS"))
                            setGenelAyarlarYonlendirilecekURL((y) => y = '/hesabim')
                        }
                        else if (( genelAyarlarSimdikiAn - genelAyarlarGuncelemeTarihi) > (1000 * 60 * 60 * 24 * 30)) {
                            console.log("profilGetir else if (guncellemeTarihi < +genelAyarlarSimdikiAn + (1000 * 60 * 60 * 24 * 30)) {")
                            toast.error(t("flashMessages:YourProfileIsIncompleteFS"))
                            toast.success(t("flashMessages:YouMustAtLeastChangeUsernameFS"))
                            setGenelAyarlarYonlendirilecekURL((y) => y = '/hesabim')
                    }
                    else {
                        console.log("profilGetir else editMode: ", editMode)
                        if(!editMode) {
                            setGenelAyarlarYonlendirilecekURL((y) => y = '/')
                        }
                    }
                }
                else {
                    setGenelAyarlarYonlendirilecekURL((y) => y = '/')
                }
                setBeklemekte({beklemeMesaji: t("flashMessages:ProfilCekiliyorFS"),
                    beklesin: false})
                setYukluyor(false)
            }
        } catch (err) {
            console.log(err)
            setYukluyor(false)
            setBeklemekte({beklemeMesaji: t("flashMessages:ProfilCekiliyorFS"),
                beklesin: false})
        }
    }

    return { profilGetir, yukluyor, getirilenProfil, setEditMode }
}