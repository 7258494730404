import React, { useRef } from 'react'
import { NavLink } from 'react-router-dom'
import { FaBars } from 'react-icons/fa'
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";

import './../i18n'

import './navbar.css'
import useWindowDimensions from '../kancalar/pencereBuyuklugunuKullan';
import { useKimlikDogrulamaBaglami } from '../kancalar/kullaniciBaglamiKullan';
import { useCikis } from '../kancalar/cikisYapKullan';

const throbVariant = {
    inital: {
        x: 5
    },
    throb: {
        scale:[1, 1.1],
        transition:{repeat: Infinity, duration:1}
    }
}

const Navbar = () => {
    const { t, i18n } = useTranslation();
    const { genislik } = useWindowDimensions();
    const AnimatedLink = motion(NavLink)
    const { kullanici } = useKimlikDogrulamaBaglami()
    const { cikisYap } = useCikis()

    const navCompRef = useRef();
    const aboutUsRef = useRef();
    const navCenterRef = useRef();
    const navLoginRef = useRef();
    const navLanguageRef = useRef();
    const navLoginDropDownRef = useRef();
    const loginDropDownUlRef = useRef();
    const languageDropDownUlRef = useRef();
    // const languageAlignRightUlRef = useRef();
    const navButtonRef = useRef();

    const changeLanguageNow = (lang) => {
        i18n.changeLanguage(lang)
        localStorage.setItem('i18nextLng', lang);
    }

    const cikisIsle = () => {
        cikisYap()
    }

    // console.log("navbar durum: ", kullanici)

    const mobilMenuyuGosterGizle = () => {
        navCompRef.current.classList.toggle("navOpened")
        navCenterRef.current.classList.toggle("visibleCls")
        navCenterRef.current.classList.toggle("invisibleCls")
        navLoginRef.current.classList.toggle("visibleCls")
        navLoginRef.current.classList.toggle("invisibleCls")
        navLanguageRef.current.classList.toggle("visibleCls")
        navLanguageRef.current.classList.toggle("invisibleCls")
    }
    return (
        <div className='headerCls'>
            <nav ref={navCompRef}>
                <div className='navLeftSideCls'>
                <AnimatedLink to={"/"} className= 'logoCls'
                    variants={throbVariant}
                    initial="initial"
                    animate="throb"
                >
                {t('genericStrings:web_site_name')}
                </AnimatedLink>
                </div>
                <div ref={navCenterRef} className={genislik <720 ? 'navAboutCls invisibleCls' : 'navAboutCls' }>
                <NavLink ref={aboutUsRef} className='aboutUs' >{t('regularMenus:aboutUsMenu')}</NavLink>
                </div>
                <div ref={navLoginRef} className={genislik <720 ? 'navLoginCls invisibleCls': 'navLoginCls'}>
                    <ul ref={navLoginDropDownRef} className='navBarDropDownCls'>
                        <li className='navBarDropDownLiContainerCls'>
                            { kullanici && (
                                    <>
                                        <NavLink
                                        to={'#'}
                                        >
                                        {t('genericStrings:myAccountS')}
                                        </NavLink>
                                        <ul ref={loginDropDownUlRef}>
                                            <li className='logInCls'>
                                                <NavLink 
                                                to="/hesabim/edit" >
                                                {t('genericStrings:myAccountDetailsS')}
                                                </NavLink>
                                            </li>
                                            <li className='logInCls'>
                                                <NavLink className='redspanCls' 
                                                onClick={cikisIsle} >
                                                {t('regularMenus:logoutMenu')}
                                                </NavLink>
                                            </li>
                                        </ul>
                                    </>
                                )
                            }
                            { !kullanici && (
                                <>
                                    <NavLink 
                                    to={'#'}
                                    >{t('regularMenus:loginRegisterMenu')}
                                    </NavLink>
                                    <ul ref={loginDropDownUlRef}>
                                        <li className='logInCls'>
                                        <NavLink to={'signin'}>{t('regularMenus:loginMenu')}</NavLink>
                                        </li>
                                        <li className='logInCls'>
                                        <NavLink to={'/'}>{t('regularMenus:registerMenu')}</NavLink>
                                        </li>
                                    </ul>
                                </>
                                )
                            }
                        </li>
                    </ul>
                </div>
                <div ref={navLanguageRef} className={genislik <720 ? 'navLanguageCls invisibleCls' : 'navLanguageCls' } >
                    <ul className='navBarDropDownCls'>
                        <li>
                            <NavLink 
                            // onTouchStart={genislik < 720 && showLanguageDropDown}
                            >{t('linguisticallyReverseMenuItems:guilangSelectS')}</NavLink>
                            <ul ref={languageDropDownUlRef}>
                                <li className='logInCls'>
                                <NavLink to="#" onClick={() => {changeLanguageNow('tr')}}>{t('linguisticallyReverseMenuItems:guilangTurS')}</NavLink>
                                </li>
                                <li className='logInCls'>
                                <NavLink to="#" onClick={() => {changeLanguageNow('en')}}>{t('linguisticallyReverseMenuItems:guilangEngS')}</NavLink>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <button ref={navButtonRef} className='nav-btn'onClick={mobilMenuyuGosterGizle}>
                    <FaBars/>
                </button>
            </nav>
        </div>
    );
};

export default Navbar;