import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { Toaster } from 'react-hot-toast'
import { ErrorBoundary } from 'react-error-boundary'

import { useGenelAyarlarBaglami } from '../baglam/GenelAyarlarSaglayici'

import Navbar from '../Bilesenler/Navbar'
import Footer from '../Bilesenler/Footer'
import Demo from '../Bilesenler/Demo'
import EkmekKirintilari from '../Bilesenler/EkmekKirintilari'
import BeklemeBileseni from '../Bilesenler/BeklemeBileseni'
// import { useKimlikDogrulamaBaglami } from '../kancalar/kullaniciBaglamiKullan'

function KokYerlesim() {
    const { demoState,
        setGenelAyarlarSimdikiAn,
    } = useGenelAyarlarBaglami()

    useEffect(() => {
        setGenelAyarlarSimdikiAn((sa) => sa = +new Date())
    }, [])

    return (
        <div id="mainBodyId" className='kokYerlesim'>
            <Navbar/>
            <main className='homePage'>
                <section className='mainSection'>
                    <EkmekKirintilari/>
                    <BeklemeBileseni/>
                    <h1 className='demoDivCls'>Responsive halini de ele al. Menüyü dokunmatik hale getir.</h1>
                    <h1 className='demoDivCls'>Form doğrulama Signinde tamamlanmamış gibi. Şimdi profili doldur.</h1>
                    {demoState && <Demo/>}
                    <ErrorBoundary>
                        <Outlet/>
                    </ErrorBoundary>
                    {/* <SignUp/> */}
                </section>
            </main>
            <Toaster position="top-center" />
            <Footer/>
        </div>
    )
}

export default KokYerlesim