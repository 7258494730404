import { useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

import { useKimlikDogrulamaBaglami } from '../kancalar/kullaniciBaglamiKullan'
import { useGenelAyarlarBaglami } from "../baglam/GenelAyarlarSaglayici";

export const useIsaretTazele = () => {
    const { t } = useTranslation();
    const [ yukluyorIsaret, setYukluyorIsaret] = useState(false)
    const [ yonlendirilecekURLIsaret, setYonlendirilecekURLIsaret] = useState('')
    const { dispatch, kullanici } = useKimlikDogrulamaBaglami()
    const { kabulEdilenDil, setBeklemekte } = useGenelAyarlarBaglami()

    const isaretTazele = async () => {
        try {
            setYukluyorIsaret(true)
            // console.log("kabulEdilenDil: ", kabulEdilenDil)
            setBeklemekte({beklemeMesaji: "",
                beklesin: true})
            const yanit = await fetch(`/isarettazele`, {
            // const yanit = await fetch(BASEURL+`/signupapi`, {
                credentials: 'include',
                method: "POST",
                headers: {
                    "access-control-allow-origin" : "*",
                    "Accept": "application/json",
                    "Content-type": "application/json; charset=UTF-8",
                    "Authorization": "Bearer " + kullanici.isaret,
                    "Accept-Language": kabulEdilenDil,
                    "Content-Language": kabulEdilenDil
                },
                body: ""
            })
            // console.log("profili getir ulan")
            console.log("tazele yanit: ", yanit)
            // const cekilenVeri = await yanit.json()
            if (!yanit.ok) {
                if(yanit.status === 401){
                    /* if ((cekilenVeri.sonuc === "Tekrar Giris Yap") ){
                        localStorage.removeItem('kullanici')
                        toast.error(t("flashMessages:PleaseLogInFS"))
                        dispatch({type: 'SIGNOUT', payload: null})
                    }
                    if ((cekilenVeri.sonuc === "Sifre Sifirla") ){
                        localStorage.removeItem('kullanici')
                        toast.error(t("flashMessages:YourPassWordMustBeResetFS"))
                        dispatch({type: 'SIGNOUT', payload: null})
                    }
                    else {
                        toast.error(t("flashMessages:notAuthorizedToDoTheAction"))
                    } */
                }
                else if(yanit.status === 500){
                    toast.error(t("flashMessages:server500ErrorFS"))
                } 
                setYukluyorIsaret(false)
                setBeklemekte({beklemeMesaji: "",
                    beklesin: false})
            }
            else { //(!yanit.ok) {
                // console.log("profilGetir cekilenVeri.profilVerileri: ")
                const isaret = yanit.headers.get('authorization')?.split(" ")[1] || ''
                let localStorageKullanici = JSON.parse(localStorage.getItem('kullanici'))
                localStorageKullanici.isaret = isaret
                // console.log("localStorageKullanici: ", localStorageKullanici)
                localStorage.setItem("kullanici", JSON.stringify(localStorageKullanici))
                dispatch({type: 'PROFILE_UPDATED', payload: localStorageKullanici})
                toast.error(t("flashMessages:YourProfileIsIncompleteFS"))
                // setGetirilenProfil(cekilenVeri.profilVerileri)
                console.log("İşaret Tazeledeki Mantık Doğru Mu?")
                setBeklemekte({beklemeMesaji: "",
                    beklesin: false})
            }
        } catch (err) {
            console.log(err)
            setYukluyorIsaret(false)
            setBeklemekte({beklemeMesaji: "",
                beklesin: false})
        }
    }

    return { isaretTazele, yukluyorIsaret, yonlendirilecekURLIsaret }
}