import { useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

import { useKimlikDogrulamaBaglami } from '../kancalar/kullaniciBaglamiKullan'
import { useGenelAyarlarBaglami } from "../baglam/GenelAyarlarSaglayici";

export const useCikis = () => {
    const { t } = useTranslation();
    const [ yukluyor, setYukluyor] = useState(false)
    const { dispatch, kullanici } = useKimlikDogrulamaBaglami()
    const { kabulEdilenDil, setBeklemekte, setGenelAyarlarYonlendirilecekURL } = useGenelAyarlarBaglami()

    const cikisYap = async () => {
        try {
            setYukluyor(true)

            console.log("çıkışta kabulEdilenDil: ", kabulEdilenDil)
            setBeklemekte({beklemeMesaji: t("flashMessages:CikisYapiyorFS"),
                beklesin: true})
            const yanit = await fetch(`/signoutapi`, {
                credentials: 'include',
                method: "POST",
                headers: {
                    "access-control-allow-origin" : "*",
                    "Accept": "application/json",
                    "Content-type": "application/json; charset=UTF-8",
                    "Authorization": "Bearer " + kullanici.isaret,
                    "Accept-Language": kabulEdilenDil,
                    "Content-Language": kabulEdilenDil
                },
                body: ""
            })
            // console.log("çıkış yanit: ", yanit.json())
            // console.log("çıkış yanit.data: ", yanit.data)
            if (!yanit.ok) {
                console.log("if (!yanit.ok) {")
                if(yanit.status === 401){
                    toast.error(t("flashMessages:PleaseLogInFS"))
                    localStorage.removeItem('kullanici')
                    dispatch({type: 'SIGNOUT', payload: null})
                }
                setYukluyor(true)
                setBeklemekte({beklemeMesaji: t("flashMessages:CikisYapiyorFS"),
                    beklesin: false})
            }
            else { //if (!yanit.ok) { }
                console.log("cıkışta yanit: ", yanit)
                // const veri = await yanit.json()
                // let veri = await yanit.json()
                // console.log("veri: ", veri)
                //yerel depodan sil
                localStorage.removeItem('kullanici')
                dispatch({type: 'SIGNOUT', payload: null})
                toast
                .success(t("flashMessages:SuccessFullySignedOutFS"))
                /*if (veri.sonuc === "Cikis Basarili"){
                    // bağlamdan çıkart
                    setYukluyor(false)
                }*/
                setYukluyor(false)
                setBeklemekte({beklemeMesaji: t("flashMessages:CikisYapiyorFS"),
                    beklesin: false})
                setGenelAyarlarYonlendirilecekURL((p) => p = '/')
            } // else // if (yanit.ok) {
        } catch (err) {
            console.log(err)
            setYukluyor(false)
            setBeklemekte({beklemeMesaji: t("flashMessages:CikisYapiyorFS"),
                beklesin: false})
            setGenelAyarlarYonlendirilecekURL((p) => p = '/')
        }
    }

    return { cikisYap, yukluyor }
}