import React from 'react'
import { FaSpinner } from 'react-icons/fa'
import { useGenelAyarlarBaglami } from '../baglam/GenelAyarlarSaglayici'

import './BeklemeBileseni.css'

const BeklemeBileseni = () => {
    const { beklemekte } = useGenelAyarlarBaglami()
    // console.log("BeklemeBileseni beklemekte: ", beklemekte.beklemeMesaji)
    if (beklemekte.beklesin) {
        return (
            <div className='centerHorizontalAndVerticalCls'>
            <p className='bekliyorMesaji'>{beklemekte.beklemeMesaji}</p>
                <div className='fixedContainer'>
                    <FaSpinner className="bekliyorCls"/>
                </div>
            </div>
        )
    }
    else return null
}

export default BeklemeBileseni