import { useTranslation } from "react-i18next";
import { useForm } from 'react-hook-form' 
import { DevTool} from '@hookform/devtools'
import { NavLink, useParams, useNavigate } from 'react-router-dom'
import { useKayit } from "../kancalar/kayitKullan";


import './signup.css'
import { EMAIL_REGEX, SIFRE_REGEX } from '../yararli/utils'
import AnimasyonluKarakterler from './AnimasyonluKarakterler'
import { useGenelAyarlarBaglami } from "../baglam/GenelAyarlarSaglayici";
import { useEffect } from "react";
import YonlendirenBilesen from "./YonlendirenBilesen";

function SignUp() { 
    const { demoState} = useGenelAyarlarBaglami();
    const { t } = useTranslation();
    // const yonlendir = useNavigate()

    const { restOfUrl } = useParams()

    const { kayitOl, yukluyor, sifreSifirla, yonlendirilecekURL } = useKayit()

    const {
        register, 
        control,
        handleSubmit, 
        watch,
        setValue,
        setError, 
        formState
    } = useForm({
        mode: "onChange"
    })
    const {errors} = formState

    const sifreAciklamaMesaji = t('messages:passwordMinLengthS:line1')+' - '+t('messages:passwordMinLengthS:line2') +' - '+t('messages:passwordMinLengthS:line3')
    const teyitSifreAciklamaMesaji = t('flashMessages:PasswordsMustMatchFS')
    // console.log("sifreAciklamaMesaji: ", sifreAciklamaMesaji)
    
    /* useEffect(() => {
            yonlendir(yonlendirilecekURL)
    }, [yonlendirilecekURL])
    console.log("Yukarıya yonlendir ekledim. Ama emin değilim") */

    useEffect(() => {
        if(restOfUrl.includes('@')){
            // console.log("signup et işareti var")
            setValue("email", restOfUrl)
        }
    }, [restOfUrl])

    const submitSignUp = async (data) => {
        // console.log("signup data: ", data)
        try {
            // return console.log("GEÇİCİ DEVRE DIŞI")
            await kayitOl(data)
        } catch (err) {
            console.log(err)
        }
    }

    const onError = (errors) => {
        console.log("errors: ", errors)
    }
    const sifre = watch("sifre")
    
    return (
        <section className='centerCls'>
        <YonlendirenBilesen/>
            <h1>{t('forms:registerFormFieldS')}</h1>
            {/* <h3>Form Değerleri: {JSON.stringify(formuIzle)}</h3> */}
            <form 
            className='formCls' 
            onSubmit={handleSubmit(submitSignUp, onError)}
            noValidate
            >
            <div className='formControlCls'>
                <label htmlFor='email'>{t('forms:useremailFormFieldS')} 
                </label>
                <input
                    type='text'
                    id='email'
                    {...register(
                        "email", {
                            required: {
                                value: true, 
                                message: t('messages:thisFieldIsRequiredS')
                            },
                            pattern: {
                                value: EMAIL_REGEX,
                                message: t('messages:enterAValidEmailS')
                                }
                        }
                    )}
                />
                {errors.email && <div className='redSpanCls'>{errors.email?.message} </div>}
            </div>
            <div className='formControlCls'>
                <label htmlFor='sifre'>{t('forms:userpasswordFormFieldS')} 
                </label>
                <input
                    type='password'
                    id='sifre'
                    name='sifre'
                    {...register(
                            "sifre", {
                            required:
                            {value: true, 
                            message: t('messages:enterAPasswordS')
                            },
                            pattern: {
                                value: SIFRE_REGEX,
                                message: sifreAciklamaMesaji
                            }
                        }
                    )}
                />
                {errors.sifre && <div className='redSpanCls'>{sifreAciklamaMesaji} <AnimasyonluKarakterler/> </div>}
            </div>
            <div className='formControlCls'>
                <label htmlFor='teyitSifresi'>{t('forms:userpasswordForConfirmationFormFieldS')}
                </label>
                <input
                    type='password'
                    id='teyitSifresi'
                    name='teyitSifresi'
                    // ref={teyitSifresiRef}
                    aria-describedby='teyitSifrenote'
                    // {...register('teyitSifresi')}
                    {...register(
                            "teyitSifresi", {
                            required: 
                            {value: true, 
                            message: t('messages:confirmThePasswordS')
                            },
                            pattern: {
                                value: SIFRE_REGEX,
                                message: sifreAciklamaMesaji
                            },
                            validate: (val) => val === sifre || setError("teyitSifresi")
                        }
                    )}
                />
                {errors.teyitSifresi && <div className='redSpanCls'>{ teyitSifreAciklamaMesaji } {sifreAciklamaMesaji} <AnimasyonluKarakterler/> </div>}
            </div>
            <input
                type='hidden'
                id='urltoUse'
                // {...register("urltoUse")}
                value={restOfUrl}

                //Qwerty12%
            />
            <div className='formControlCls'>
                <button className='greenBackgroundCls roundedCornersCls paddingSides5Rem marginSides5Rem' 
                // disabled={yukluyor}
                type="submit"
                >{yukluyor ? t('forms:waitFormS') : t('forms:registerFormFieldS')}
                </button>
                {/* disabled={isSubmitting}
                >{isSubmitting ? t('forms:waitFormS') : t('forms:registerFormFieldS')}</button> */}
            </div>
            </form>
            { demoState && <DevTool control={control}/> }
            <p className={sifreSifirla ? '' : 'hideItCls'}>
                <NavLink className='redspanCls' to='../sifreSifirla'>{t('forms:forgotpasswordAndWantToResetS')}
                </NavLink>
            </p>
        </section>
    )
}

export default SignUp